import React from 'react';
import { graphql } from 'gatsby';

import ContentPageBreadcrumb from '../../utils/seo/ContentPageBreadcrumb';
import Heading from '../../components/Heading';
import SEOContainer from '../Layout/SEO';
import Section from '../../components/Heading/Section';
import SectionContainer from '../Section';
import buildContentPageSeoProps from '../../utils/seo/buildContentPageSeoProps';
import notNullOrUndefined from '../../utils/misc/notNullOrUndefined';
import { isNeighborClipped } from '../Section/utils';

// Types
import { ContentPageContainerFragment } from './__generated__/ContentPageContainerFragment';

type Props = {
  contentPage: ContentPageContainerFragment;
};

export const fragment = graphql`
  fragment ContentPageContainerFragment on CMS_Contentpage {
    ...BuildContentPageSeoPropsFragment
    id
    title
    sections(sort: "order") {
      ...SectionContainerFragment
      id
    }
  }
`;

const ContentPageContainer = ({ contentPage }: Props) => {
  const seoProps = buildContentPageSeoProps(contentPage);

  const filteredSections = (contentPage.sections || []).filter(notNullOrUndefined);

  const mappedSections = filteredSections.map((section, index, sections) => (
    <SectionContainer
      index={index}
      isCritical={true}
      isNeighborClipped={isNeighborClipped({ index, sections })}
      key={section.id}
      section={section}
    />
  ));

  return (
    <>
      {/* SEO */}
      <ContentPageBreadcrumb contentPage={contentPage} />
      <SEOContainer {...seoProps} />

      {!!seoProps.title && <Heading isVisuallyHidden={true}>{seoProps.title}</Heading>}

      <Section hasNoTag={true}>{mappedSections}</Section>
    </>
  );
};

export default ContentPageContainer;
