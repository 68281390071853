import React from 'react';
import { Generic, JSONLD } from 'react-structured-data';
import { graphql } from 'gatsby';

import buildBreadcrumbListItem from './buildBreadcrumbListItem';
import buildCollectionPageUrl from '../url/buildCollectionPageUrl';
import { STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM } from './constants';

// Types
import { ContentPageBreadcrumbFragment } from './__generated__/ContentPageBreadcrumbFragment';

export const fragment = graphql`
  fragment ContentPageBreadcrumbFragment on CMS_Contentpage {
    slug
    title
  }
`;

type Props = {
  contentPage: ContentPageBreadcrumbFragment;
};

const ContentPageBreadcrumb = ({ contentPage }: Props) => {
  const contentPageTitle = contentPage.title;
  const contentSlug = contentPage.slug;

  if (!contentPageTitle || !contentSlug) {
    return null;
  }

  const contentPageUrl = buildCollectionPageUrl(contentSlug);

  const contentListItem = buildBreadcrumbListItem({ name: contentPageTitle, position: 2, url: contentPageUrl });

  const schema = {
    itemListElement: [STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM, contentListItem],
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="BreadcrumbList" schema={schema} />
    </JSONLD>
  );
};

export default ContentPageBreadcrumb;
