import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import ContentPageContainer from '../containers/ContentPage';
import LayoutContainer from '../containers/Layout';

// Types
import { ContentPageTemplateQuery } from './__generated__/ContentPageTemplateQuery';
import { PageRendererProps } from 'gatsby';

export const pageQuery = graphql`
  query ContentPageTemplateQuery($where: CMS_JSON!) {
    cms {
      contentpages(where: $where) {
        ...ContentPageContainerFragment
        logoColor
      }
    }
  }
`;

type Props = {
  data: ContentPageTemplateQuery;
} & PageRendererProps;

const ContentPageTemplate = ({ data }: Props) => {
  if (!data.cms.contentpages || data.cms.contentpages.length !== 1) {
    throw Error('Something went wrong');
  }

  const [contentpage] = data.cms.contentpages;
  const logoColor = idx(contentpage, _ => _.logoColor);

  if (!contentpage) {
    throw Error('Something went wrong');
  }

  return (
    <LayoutContainer logoColor={logoColor}>
      <ContentPageContainer contentPage={contentpage} />
    </LayoutContainer>
  );
};

export default ContentPageTemplate;
