import { graphql } from 'gatsby';

import buildContentPageUrl from '../url/buildContentPageUrl';

// Types
import { BuildContentPageSeoPropsFragment } from './__generated__/BuildContentPageSeoPropsFragment';

export const fragment = graphql`
  fragment BuildContentPageSeoPropsFragment on CMS_Contentpage {
    slug
    title
  }
`;

const buildContentPageSeoProps = (contentPage: BuildContentPageSeoPropsFragment) => {
  const slug = contentPage.slug;
  const title = contentPage.title;

  const canonicalUrl = buildContentPageUrl(slug);

  return {
    canonicalUrl,
    openGraphTitle: title,
    title,
  };
};

export default buildContentPageSeoProps;
